<template>
  <v-container v-if="display">
    <v-card flat>
      <v-toolbar flat class="headline">
        <v-btn
          icon
          text
          small
          class="mx-4"
          :to="{ name: 'displays' }"
        >
          <v-icon>
            mdi-chevron-left
          </v-icon>
        </v-btn>
        <v-avatar :color="display.lastOnlineAt === true ? 'success' : 'error'" size="32" class="mr-2">
          <v-icon dark>
            mdi-wifi{{ display.lastOnlineAt !== true ? '-off' : '' }}
          </v-icon>
        </v-avatar>
        <strong>{{ display.name }}</strong>
      </v-toolbar>
      <v-card-text class="d-flex flex-wrap">
        <v-card flat class="grow mr-auto" :max-width="$vuetify.breakpoint.smAndUp ? '500': null">
          <v-list>
            <v-list-item v-if="editing !== 'name'">
              <v-list-item-content>
                <v-list-item-title>
                  Display Name:
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{ display.name }}
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-btn
                  v-if="!editing"
                  icon
                  @click="editing = 'name'"
                >
                  <v-icon>
                    mdi-pencil
                  </v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
            <validation-provider
              v-else
              v-slot="{ errors }"
              rules="required|min:5"
              name="Name"
            >
              <v-text-field
                v-model="name"
                label="Display Name"
                type="text"
                outlined
                class="mr-5"
                :error-messages="errors"
                required
                append-outer-icon="mdi-check"
                :loading="saving"
                @click:append-outer="saveName"
                @keypress.enter="saveName"
              />
            </validation-provider>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>
                  Display Plan:
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{ display.plan | displayPlan }}
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-btn
                  outlined
                  small
                  @click="planDialog = true"
                >
                  Change Plan
                </v-btn>
              </v-list-item-action>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>
                  Last Online:
                </v-list-item-title>
                <v-list-item-subtitle>
                  <span v-if="display.lastOnlineAt === true" class="success--text">
                    Online Now
                  </span>
                  <span v-else-if="display.lastOnlineAt">
                    {{ display.lastOnlineAt | formatDate }}
                  </span>
                  <span v-else class="warning--text">
                    No connection yet
                  </span>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>
                  Display Resolution:
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{ display.resolution | formatResolution }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item v-if="display.locationName">
              <v-list-item-content>
                <v-list-item-title>
                  Display Location:
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{ display.locationName }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>
                  Layout:
                </v-list-item-title>
                <v-list-item-subtitle>
                  <router-link
                    v-if="display.layout"
                    :to="{ name: 'layout', params: { id: display.layoutId } }"
                  >
                    {{ display.layout.name }}
                  </router-link>
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-btn
                  outlined
                  small
                  @click="layoutDialog = true"
                >
                  {{ !display.layoutId ? 'Configure' : 'Change Layout' }}
                </v-btn>
              </v-list-item-action>
            </v-list-item>
            <v-list-item v-if="display.layout">
              <v-list-item-content>
                <v-list-item-title>
                  Minimum plan required:
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{ display.layout.minPlan | displayPlan }}
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-chip
                  v-if="!display.planStatus"
                  color="error"
                  dense
                >
                  Disabled
                </v-chip>
                <v-chip
                  v-else-if="display.planStatus === 'free'"
                  color="primary"
                  dense
                >
                  Watermarked
                </v-chip>
                <v-chip
                  v-else
                  color="success"
                  dense
                >
                  Enabled
                </v-chip>
              </v-list-item-action>
            </v-list-item>
            <v-list-item v-if="display.planStatus === 'free'">
              <v-list-item-content>
                <v-list-item-title>
                  Watermark Location
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{ watermarkLocation | displayWatermarkLocation }}
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-select
                  v-model="watermarkLocation"
                  :items="watermarkLocations"
                  outlined
                  dense
                  hide-details
                  style="max-width: 175px;"
                />
              </v-list-item-action>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>
                  Latest Screenshot:
                </v-list-item-title>
                <v-list-item-subtitle v-if="screenshotMetaData">
                  {{ screenshotMetaData.updated | formatDate }}
                </v-list-item-subtitle>
                <v-list-item-subtitle v-else>
                  Never
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-btn
                  outlined
                  small
                  :disabled="display.lastOnlineAt !== true"
                  @click="takeScreenshot(display.id)"
                >
                  <v-icon left>
                    mdi-camera-wireless-outline
                  </v-icon>
                  Take
                </v-btn>
              </v-list-item-action>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>
                  Live Screen View
                </v-list-item-title>
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-btn
                  outlined
                  small
                  :disabled="display.lastOnlineAt !== true"
                  @click="toggleBroadcast"
                >
                  <v-icon left>
                    mdi-video
                  </v-icon>
                  {{ stream ? 'Stop' : 'Start' }} Broadcast
                </v-btn>
              </v-list-item-action>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>
                  Mammut OS Version:
                </v-list-item-title>
                <v-list-item-subtitle>
                  Version {{ display.appVersion }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>
                  Frame Version:
                </v-list-item-title>
                <v-list-item-subtitle>
                  Version {{ display.frameVersion }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>
                  Reload Mammut-OS
                </v-list-item-title>
                <v-list-item-subtitle>
                  Click the button to re-load the Mammut OS application remotely.
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-menu offset-y>
                  <template #activator="{ on }">
                    <v-btn
                      icon
                      color="secondary"
                      v-on="on"
                    >
                      <v-icon>
                        mdi-refresh
                      </v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item @click="$emit('reload', display.id)">
                      <v-list-item-title>Reload Application</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="$emit('reload-frame', display.id)">
                      <v-list-item-title>Force Reload Frame</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="$emit('reload-query', display.id)">
                      <v-list-item-title>Reload Frame Query String</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-list-item-action>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>
                  Remove Display
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <v-btn
                  icon
                  color="error"
                  :loading="removing"
                  @click="removeDisplay"
                >
                  <v-icon>
                    mdi-delete
                  </v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-card>
        <v-card
          flat
          width="300"
          height="60vh"
        >
          <v-card
            v-show="stream"
            id="stream"
            width="100%"
            height="100%"
          />
          <v-img
            v-show="!stream && screenshotSrc"
            :src="screenshotSrc"
            width="300"
            contain
          />
        </v-card>
      </v-card-text>
    </v-card>
    <v-dialog
      v-model="layoutDialog"
      scrollable
      max-width="600"
    >
      <v-card light>
        <v-card-title style="position: relative;">
          Select a layout for {{ display.name }}
          <v-btn
            fab
            absolute
            right
            text
            class="mr-n3"
            @click="layoutDialog = false"
          >
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="pt-6">
          <v-list>
            <v-list-item
              v-for="layout in layouts"
              :key="layout.id"
            >
              <v-list-item-content>
                <v-list-item-title>
                  {{ layout.name }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  Requires {{ layout.minPlan | displayPlan }}
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-icon>
                <v-btn
                  :loading="useLayout === layout.id"
                  :disabled="getPlanStatus({ minPlan: layout.minPlan, plan: display.plan }) === false"
                  @click="linkDisplayToLayout(layout.id)"
                >
                  <v-icon
                    left
                    color="primary"
                  >
                    mdi-link
                  </v-icon>
                  Use
                </v-btn>
              </v-list-item-icon>
            </v-list-item>
          </v-list>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <!-- eslint-disable -->
          <v-btn
            text
            color="primary"
            :to="{
              name: 'layouts-new',
              params:
              {
                displayId: display.id,
                displayName: display.name,
                width: display.resolution.width,
                height: display.resolution.height
              }
            }"
          >
            Or Create A New Layout
          </v-btn>
          <!-- eslint-enable -->
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="planDialog"
      scrollable
      max-width="500"
    >
      <v-card light>
        <v-overlay
          v-if="changingPlan"
          absolute
          opacity="0.1"
        >
          <card-loader />
        </v-overlay>
        <v-card-title style="position: relative;">
          Select a plan
          <v-btn
            fab
            absolute
            right
            text
            class="mr-n3"
            @click="planDialog = false"
          >
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="pt-6 d-flex flex-column">
          <v-chip
            v-if="organization.remainingTrialDays >= 0"
            class="ma-2"
            color="white"
            light
            large
            label
            :disabled="organization.isUsingTrialDisplay"
            @click="changePlan('trial')"
          >
            <v-avatar left :color="organization.isUsingTrialDisplay ? 'grey' : 'success'" class="white--text" tile>
              {{ organization.isUsingTrialDisplay ? '0' : '1' }}
            </v-avatar>
            Trial Plan
          </v-chip>
          <v-chip
            class="ma-2"
            color="white"
            large
            light
            label
            :disabled="!display.plan || display.plan === 'free'"
            @click="changePlan('free')"
          >
            <v-avatar left color="green" class="white--text" tile>
              ~
            </v-avatar>
            Free Plan
          </v-chip>
          <v-chip
            v-for="plan in ['advertising', 'view', 'touch']"
            :key="plan"
            class="ma-2"
            color="white"
            large
            light
            label
            :disabled="display.plan === plan || subscription[plan] === 0"
            @click="changePlan(plan)"
          >
            <v-avatar left color="grey" class="white--text" tile>
              {{ subscription[plan] || 0 }}
            </v-avatar>
            {{ plan | displayPlan }}
          </v-chip>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            class="ma-2"
            color="primary"
            outlined
            :to="{ name: 'subscription' }"
          >
            Add plans
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import { storageRef, db } from '@/plugins/firebase'
import { getPlanStatus } from '@/utils/get_plan_status'
const watermarkLocations = [
  {
    text: 'Bottom Right',
    value: 'bottom-right'
  },
  {
    text: 'Bottom Left',
    value: 'bottom-left'
  },
  {
    text: 'Top Right',
    value: 'top-right'
  },
  {
    text: 'Top Left',
    value: 'top-left'
  }
]

export default {
  name: 'Display',
  filters: {
    displayWatermarkLocation(location) {
      const watermarkLocation = watermarkLocations.find(x => x.value === location)
      return watermarkLocation ? watermarkLocation.text : 'Unset'
    }
  },
  data: () => ({
    editing: false,
    saving: false,
    reloading: false,
    removing: false,
    name: '',
    layoutDialog: false,
    useLayout: null,
    stream: null,
    screenshot: null,
    screenshotMetaData: {},
    plans: ['free', 'advertising', 'view', 'touch', 'trial'],
    planDialog: false,
    changingPlan: false,
    watermarkLocation: null,
    watermarkLocations
  }),
  computed: {
    display() {
      return this.$store.getters.displayById(this.$route.params.id)
    },
    screenshotSrc() {
      if (!this.screenshot || !this.display.lastScreenshotAt) {
        return null
      }
      // convert screenshot date to string
      const lastScreenshotAt = this.display.lastScreenshotAt.toDate().toJSON()
      // update query string of screenshot src
      return [this.screenshot, lastScreenshotAt].join(this.screenshot.includes('?') ? '&' : '?')
    },
    ...mapGetters(['organization', 'layouts', 'subscription'])
  },
  watch: {
    'display.id': {
      handler(id) {
        !!id && this.loadScreenshot()
        this.$client.join(null, id, null, null, (uid) => {
          console.warn(`Joined channel as audience with user id ${uid}`)
        })
      },
      immediate: true
    },
    'display.name': {
      handler(name) {
        this.name = name
      },
      immediate: true
    },
    'display.watermarkLocation': {
      handler(watermarkLocation) {
        this.watermarkLocation = watermarkLocation || 'bottom-right'
      },
      immediate: true
    },
    watermarkLocation: {
      async handler(watermarkLocation) {
        if (watermarkLocation === this.display.watermarkLocation) {
          return
        }
        await this.$db.collection('displays').doc(this.display.id).update({ watermarkLocation })
        this.$store.dispatch('newSnackbar', { type: 'success', message: 'Watermark location updated.' })
      }
    }
  },
  async mounted() {
    await new Promise((resolve, reject) => {
      this.$client.setClientRole('audience', (err) => {
        if (err) {
          return reject(err)
        }
        return resolve()
      })
    })
    this.$client.on('stream-added', ({ stream }) => {
      console.log('stream added', stream)
      // Subscribe to the stream.
      this.$client.subscribe(stream, function(err) {
        console.log('[ERROR] : subscribe stream failed', err)
      })
    })
    this.$client.on('stream-subscribed', ({ stream }) => {
      console.log('stream-subscribed', stream)
      this.stream = stream
      this.$nextTick(() => {
        this.stream.play('stream', { muted: true })
      })
    })
    this.$client.on('peer-leave', ({ uid }) => {
      console.warn('peer-leave', uid)
      if (uid === this.stream.streamId) {
        this.stream.stop()
        this.stream = null
      }
    })
  },
  beforeUnmount() {
    this.$client.leave()
  },
  methods: {
    async loadScreenshot() {
      if (this.display && this.display.id) {
        this.screenshot = await storageRef.child(`screenshots/${this.display.id}.png`).getDownloadURL()
      }
    },
    takeScreenshot(id) {
      this.$emit('screenshot', id)
    },
    toggleBroadcast() {
      if (this.stream) {
        this.$emit('stop-broadcast', this.display.id)
        return
      }
      this.$emit('broadcast', this.display.id)
    },
    async linkDisplayToLayout(layoutId) {
      this.useLayout = layoutId
      await this.$db.collection('displays').doc(this.display.id).update({
        layoutId
      })
      this.$router.push({ name: 'layout', params: { id: layoutId } })
    },
    async saveName() {
      this.saving = 'name'
      if (this.display.name !== this.name) {
        await this.$db.collection('displays').doc(this.display.id).update({
          name: this.name
        })
      }
      this.saving = false
      this.editing = false
    },
    async removeDisplay() {
      if (!confirm('Warning! This will delete and remove the pairing of the display. Proceed?')) {
        return
      }
      const machineId = this.display.id
      this.removing = true
      // first delete the screenshot from storage
      try {
        await this.$storageRef.child(`screenshots/${machineId}.png`).delete()
      } catch (e) {
        // eslint-disable-next-line
        console.log(e)
      }
      // then delete the display from firestore
      await db.collection('displays').doc(machineId).delete()
      this.$emit('reload', machineId)
      this.$router.push({ name: 'displays' })
    },
    async changePlan(plan) {
      this.changingPlan = true
      try {
        await this.$db.collection('displays').doc(this.display.id).update({
          plan,
          planChangedAt: this.$nowstamp()
        })
        this.$store.dispatch('newSnackbar', { type: 'success', message: 'Display plan successfully updated.' })
      } catch (error) {
        this.$store.dispatch('newSnackbar', { type: 'error', message: 'Display plan could not be updated.', error })
      }
      this.planDialog = false
      this.changingPlan = false
    },
    getPlanStatus
  }
}
</script>
